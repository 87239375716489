import request from '@/utils/request'
import axios, { AxiosPromise } from 'axios'

export const AxiosSource = { Source: axios.CancelToken.source() }
/**
 * Api Call
 * @param params
 * @returns
 */
export function fetch(params: any): AxiosPromise {
    return request({
        method: 'POST',
        params,
        cancelToken: AxiosSource.Source.token
    })
}

export function getImage(): AxiosPromise {
    return request({
        url: `common/public/verifycode/${new Date().getTime()}`,
        responseType: 'blob'
    })
}

export function login(_params: any): AxiosPromise {
    const { params, token } = _params
    return request({
        method: 'POST',
        url: 'user/login/submit',
        params,
        headers: { 'Access-Token': token }
    })
}

export function demoLogin(model: any): AxiosPromise {
    const { verifyCode, header } = model

    const hallType = parseInt(process.env.HALL_TYPE)

    let urlPath = 'guestSubmit'

    if (hallType === 2) urlPath = 'guestSubmit7'
    else if (hallType === 3) urlPath = 'guestSubmit8'
    else if (hallType === 4) urlPath = 'guestSubmit10'
    else if (hallType === 12) urlPath = 'guestSubmit12'
    else if (hallType === 11) urlPath = 'guestSubmit11'

    return request({
        url: `user/login/${urlPath}`,
        method: 'POST',
        params: { verifyCode },
        headers: header
    })
}

export function getHallData(params: any): AxiosPromise {
    const hallType = parseInt(process.env.HALL_TYPE)

    let urlPath = 'common/hall/getHallData'

    if (hallType === 2) urlPath = 'common/hall/getHallData7'
    else if (hallType === 3) urlPath = 'common/hall/getHallData8'
    else if (hallType === 4) urlPath = 'common/hall/getHallData10'
    else if (hallType === 12) urlPath = 'common/hall/getHallData12'
    else if (hallType === 11) urlPath = 'common/hall/getHallData11'
    return request({
        url: urlPath,
        method: 'GET',
        params
    })
}

export function getTableDetail(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'common/gameTable/getDetail',
        params
    })
}

export function getBetRecordList(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'common/hall/getBetRecordList',
        params
    })
}

export function logOut(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'user/login/logout',
        params
    })
}

export function joinTable(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/changeTable',
        params
    })
}

export function callGunner(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/callGunner',
        params
    })
}

export function operatStatus(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/operatStatus',
        params
    })
}

export function flyBet(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/flyBet',
        params
    })
}

export function giveFeeVerify(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/giveFeeVerify',
        params
    })
}

export function giveFee(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/giveFee',
        params
    })
}

export function saveBet(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/lottery/saveBet',
        params
    })
}

export function editPassword(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'user/login/editPasswordSubmit',
        params
    })
}

export function pauseRequest(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/pause',
        params
    })
}

export function openPokeRequest(params: any): AxiosPromise {
    return request({
        method: 'POST',
        url: 'common/gameTable/openPoke',
        params
    })
}

export function getMultiTableDetail(params: any): AxiosPromise {
    return request({
        method: 'GET',
        url: 'common/gameTable/getMutliDetail',
        params
    })
}

export function pingServer(line: number, url: string): AxiosPromise<unknown> {
    let api = ''
    const endPointPath = 'ctrl/dList'

    if (line === -1) {
        api = `${url}/${endPointPath}`
    } else if (line === 1) {
        api = `${process.env.API1}/${endPointPath}`
    } else if (line === 2) {
        api = `${process.env.API2}/${endPointPath}`
    } else if (line === 3) {
        api = `${process.env.API3}/${endPointPath}`
    }

    const params = {
        safeUrl: 'QWE~!@wwCa99AS'
    }

    return request({
        baseURL: api,
        method: 'GET',
        params,
        responseType: 'arraybuffer'
    })
}
